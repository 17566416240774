import {makeStyles} from "tss-react/mui";

/**
 * @type {object}
 */
// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles()((theme) => {
    return {
        root: {
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            color: theme.palette.grey[700]
        },
        menuItem: {
            position: "relative",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            height: theme.custom.headerHeight,
            margin: 0,
            backgroundColor: "transparent"
        },
        link: {
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: "0.875rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            height: "100%",
            width: "100%",
            color: theme.palette.grey[700],
            textDecoration: "none",
            textTransform: "uppercase"
        },
        initials: {
            width: theme.spacing("l"),
            height: theme.spacing("l"),
            borderRadius: "50%",
            lineHeight: theme.spacing("l"),
            background: theme.palette.grey[200],
            display: "flex",
            justifyContent: "center"
        },
        editing: {
            border: `2px solid ${theme.palette.primary.main}`,
            lineHeight: "1.75rem"
        },
        divider: {
            width: "1px",
            height: theme.spacing("l"),
            background: theme.palette.grey[200],
            marginRight: theme.spacing("s"),
            marginLeft: theme.spacing("s")
        },
        label: {
            fontSize: "0.6875rem",
            color: theme.palette.grey[500],
            margin: `0 ${theme.spacing("s")}`,
            [theme.breakpoints.down("sm")]: {
                display: "none"
            }
        }
    };
});
