// @ts-check
import React, {useContext, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import config from "../../../../config/config.json";
import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import {selectChangeHistory, selectOpData} from "../../../pages/day_view/day_view_selectors";
import {selectLastPublishedSession} from "../../../pages/op_management/op_management_selectors";
import {selectCurrentTimezone, selectLanguage} from "../../../redux/app_selectors";
import {selectFeSettings} from "../../fe_settings/fe_settings_selectors";
import {selectFullNamesArray, selectStandardNamesArray} from "../../private_data/private_data_selectors";
import getPersonIdsFromChangeHistory from "../../private_data/utils/get_person_ids_from_change_history";
import {selectRoomInfos} from "../../rooms/rooms_selectors";
import DataTable from "../../shared/data_table/data_table";
import {formatChangesByCategories, formatChangesPerRow, formatNewSurgeriesFromOpData} from "../helpers";
import useStyles from "../info_layer.styles";

/**
 * render ChangeLogs component
 * @return {React.ReactElement}
 */
export const ChangeLogs = () => {
    const {classes} = useStyles();
    const {t} = useTranslation();
    const {now, areSame, fromISO, startOf, formatFromISOSetTimezone} = useContext(DateContext);

    /** @type {Array<ResourceHistoryChangeInfoLayer>} */
    const changeHistoryList = useSelector(selectChangeHistory);
    /** @type {Array<PlanBox>} */
    const opData = useSelector(selectOpData); // Since the info layer can be opend only today, opData is all the op of today
    const {publishedAt} = useSelector(selectLastPublishedSession);
    const {
        emergencyThreshold,
        theme: {locations}
    } = useSelector(selectFeSettings);

    // Filter surgeries that were registered today (authored = today) and format the structure
    const newSurgeries = useMemo(
        () =>
            formatNewSurgeriesFromOpData(
                opData.filter(
                    (op) =>
                        op._authoredOn &&
                        areSame(fromISO(op._authoredOn), now(), "day") && // registered today
                        publishedAt &&
                        fromISO(publishedAt) >= startOf(fromISO(op._authoredOn), "minute") // registered before last published
                ),
                emergencyThreshold
            ),
        [opData]
    );

    const allPatientIds = [...newSurgeries.map((op) => op.patientId), ...getPersonIdsFromChangeHistory(changeHistoryList).patientIds];
    const patientNamesList = useSelector(selectFullNamesArray({ids: [...new Set(allPatientIds)], type: "patient"}));

    const practitionerNamesList = useSelector(
        selectStandardNamesArray({ids: getPersonIdsFromChangeHistory(changeHistoryList).practitionerIds, type: "practitioner"})
    );
    const roomInfos = useSelector(selectRoomInfos);
    // Set short names if available
    const roomShortNames = roomInfos.map((roomInfo) => ({id: roomInfo.id, name: locations?.[roomInfo.id]?.label || roomInfo.name}));

    const timezone = useSelector(selectCurrentTimezone);
    const language = useSelector(selectLanguage);

    const {
        TEXT_PUNCTUATION: {HYPHEN}
    } = config;

    const labels = [
        {id: "patientId", label: t("ChangeLogs.patient"), width: "25%", setTitle: true},
        {id: "opStart", label: t("ChangeLogs.opStart"), width: "20%", setTitle: true},
        {id: "changes", label: t("ChangeLogs.details"), width: "55%", setTitle: true}
    ];

    const dataFormatted = [];

    // One row = a change made to the same surgery at the same time
    // @ts-ignore
    const changesPerRowList = formatChangesPerRow([...changeHistoryList, ...newSurgeries]);

    // format to the format of the data props of TableData
    changesPerRowList.forEach((changePerRow) => {
        const patientName = patientNamesList.find((patient) => patient.id === changePerRow.patientId)?.name;
        const displayPatientName = patientName || HYPHEN;
        const id = `${changePerRow.id}-${changePerRow.timestamp}`;
        dataFormatted.push({
            id,
            key: id,
            patientId: displayPatientName,
            opStart: changePerRow.start,
            isEmergency: changePerRow.isEmergency,
            display: {
                patientId: displayPatientName,
                opStart: changePerRow.start ? formatFromISOSetTimezone(changePerRow.start, DATE_FORMATS.TIME, timezone) : HYPHEN,
                changes: formatChangesByCategories(
                    changePerRow.changes,
                    practitionerNamesList,
                    roomShortNames,
                    timezone,
                    language,
                    changePerRow.isEmergency
                ).map((el) => (
                    <div className={classes.change} data-testid={el.content} key={el.key + el.content} title={el.content}>
                        {el.content}
                    </div>
                ))
            },
            tooltip: {
                patientId: displayPatientName
            }
        });
    });

    return (
        <div className={classes.changeLogWrapper}>
            <DataTable
                data={dataFormatted}
                disableSortColumns={labels.map((label) => label.id)}
                isPaginationVisible={false}
                labels={labels}
            />
        </div>
    );
};

export default ChangeLogs;
