// @ts-check
import React from "react";
import {useTranslation} from "react-i18next";

import ChangeLogs from "./components/change_logs";
import Info1And2 from "./components/info1_and_2";
import Info3 from "./components/info3";
import useStyles from "./info_layer.styles";

/**
 * render InfoLayer component
 * @return {React.ReactElement}
 */
export const InfoLayer = () => {
    const {classes} = useStyles();
    const {t} = useTranslation();

    return (
        <div className={classes.root}>
            <div className={classes.title}>{t("InfoLayer.title")}</div>
            <div className={classes.content}>
                <ChangeLogs />
                <div>
                    <Info1And2 />
                    <Info3 />
                </div>
            </div>
        </div>
    );
};

export default InfoLayer;
