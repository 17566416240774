// @ts-check
import {Box, Menu, MenuItem, useMediaQuery} from "@mui/material";
import {string} from "prop-types";
import React, {Fragment, useEffect} from "react";
import {Trans, useTranslation} from "react-i18next";
import {useAuth} from "react-oidc-context";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import {getUsernamesAction} from "../../pages/op_management/op_management_actions";
import {selectStatus, selectUsernames} from "../../pages/op_management/op_management_selectors";
import {logoutAction} from "../../redux/actions";
import {selectCurrentUser} from "../../redux/app_selectors";
import formatUsername from "../../utils/format_username";
import {useStyles} from "./account_menu.styles";

/**
 * The Account Menu is open when the user badge on the app header is clicked
 *
 * @param {object} props The props
 * @param {string} [props.editedBy] The name of the last person that edited the plan
 * @return {JSX.Element}
 */
const AccountMenu = ({editedBy}) => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const downXs = useMediaQuery((/** @type {import("@mui/material").Theme} */ theme) => theme.breakpoints.down("sm"));
    const auth = useAuth();

    const user = useSelector(selectCurrentUser);
    const usernames = useSelector(selectUsernames);
    const status = useSelector(selectStatus);

    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);

    useEffect(() => {
        if (status?.session) {
            dispatch(getUsernamesAction());
        }
    }, [status]);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleAccountPage = () => {
        setAnchorEl(null);
        navigate("/account");
    };

    const handleLogout = async () => {
        setAnchorEl(null);
        dispatch(logoutAction(auth?.user?.refresh_token));
    };

    const editUserInitials =
        usernames && usernames[editedBy] ? usernames[editedBy].firstName.charAt(0).concat(usernames[editedBy].lastName.charAt(0)) : null;
    const editUsername = usernames && usernames[editedBy] && formatUsername(editedBy, usernames);
    return (
        <Fragment>
            {editedBy && editedBy !== user.email && (
                <Fragment>
                    <div className={classes.label}>
                        <div>{t("AccountMenu.editing1")}</div>
                        <Trans components={{bold: <strong />}} i18nKey="AccountMenu.editing2" values={{name: editUsername}} />
                    </div>
                    <div
                        className={cx(classes.initials, classes.editing)}
                        title={downXs ? t("AccountMenu.editingText", {name: editUsername}) : null}
                    >
                        {editUserInitials}
                    </div>
                </Fragment>
            )}
            <div className={classes.divider} />
            <div
                aria-controls="simple-menu"
                aria-haspopup="true"
                className={classes.root}
                data-testid="userMenuOpener"
                role={"button"}
                tabIndex={0}
                onClick={handleMenuOpen}
                onKeyDown={(e) => e.key === "Enter" && handleMenuOpen(e)}
            >
                <div className={classes.initials}>{user.initials}</div>
            </div>
            <Menu
                anchorEl={anchorEl}
                data-testid="userMenu"
                id="simple-menu"
                keepMounted
                open={Boolean(anchorEl)}
                transformOrigin={{vertical: "top", horizontal: "right"}}
                onClose={handleMenuClose}
            >
                <MenuItem className={classes.menuItem} data-testid="userMenuAccount" key="my-account" onClick={handleAccountPage}>
                    <Box className={classes.link}>
                        <Box ml={0.5}>{t("AccountMenu.my_account")}</Box>
                    </Box>
                </MenuItem>
                <MenuItem className={classes.menuItem} data-testid="userMenuLogout" key="logout" onClick={handleLogout}>
                    <Box className={classes.link}>
                        <Box ml={0.5}>{t("AccountMenu.logout")}</Box>
                    </Box>
                </MenuItem>
            </Menu>
        </Fragment>
    );
};
AccountMenu.propTypes = {
    editedBy: string
};
export default AccountMenu;
