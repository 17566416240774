/**
 * @fileoverview API for style settings
 */

import axiosClient from "../../middleware/axios.middelware";

const URL = "/orchestrator/organization/";

/**
 * fetch style settings
 * @param {String} organizationId
 * @return {Promise}
 */
function fetchFeSettings(organizationId) {
    return axiosClient.get(`${URL}${organizationId}/settings/frontend`);
}

export {fetchFeSettings};
