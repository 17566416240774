import {makeStyles} from "tss-react/mui";

/** @type {Object} */
export default makeStyles()((theme) => ({
    root: {
        width: "100%",
        background: theme.palette.background.paper
    },
    title: {
        height: "78px",
        textTransform: "uppercase",
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        color: theme.palette.common.black,
        fontWeight: theme.typography.fontWeightBold,
        padding: "30px 20px"
    },
    content: {
        height: "calc(100% - 78px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    changeLogWrapper: {
        "padding": "0 20px",
        "overflow": "auto",
        "& .MuiTableCell-stickyHeader": {
            backgroundColor: theme.palette.background.paper
        },
        "& .MuiTableRow-root": {
            height: "30px"
        }
    },
    change: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    },
    additionalInfoWrapper: {
        display: "flex",
        padding: "0 20px",
        justifyContent: "space-between"
    },
    infoBox: {
        width: "220px",
        height: "102px"
    },
    infoBoxTitle: {
        height: "30px",
        display: "flex",
        alignItems: "center",
        padding: "5px 10px",
        fontWeight: theme.typography.fontWeightBold,
        borderBottom: `1px solid ${theme.palette.grey[200]}`
    },
    infoBoxNames: {
        padding: "5px 10px",
        height: "calc(100% - 30px)",
        borderBottom: `1px solid ${theme.palette.grey[200]}`
    },
    Info3Wrapper: {
        minHeight: "200px",
        padding: "20px"
    },
    info3: {
        minHeight: "200px",
        width: "100%",
        padding: "10px 0"
    },
    dutieRow: {
        display: "flex",
        padding: "0 10px",
        height: "30px",
        lineHeight: "30px",
        borderBottom: `1px solid ${theme.palette.grey[200]}`
    },
    label: {
        width: "220px",
        marginRight: "20px",
        color: theme.palette.grey[600],
        flex: "auto 0 0"
    },
    value: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    }
}));
