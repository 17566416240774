import {createReducer} from "../../redux/utils/reducer";
import STATUS from "../../redux/utils/status";
import ActionTypes from "./fe_settings_action_types";

const initialState = {
    disciplineColors: [],
    theme: {},
    logo: "",
    translation: "",
    alias: "",
    surgeryAssignment: {},
    statusFeSettings: STATUS.IDLE,
    errorFeSettings: null
};

const handleFeSettingsRequest = (state) => ({
    ...state,
    statusFeSettings: STATUS.PENDING,
    errorFeSettings: null
});

const handleFeSettingsFailure = (state, {error}) => ({
    ...state,
    statusFeSettings: STATUS.REJECTED,
    errorFeSettings: error
});

const handleFeSettingsSuccess = (state, {payload}) => ({
    ...state,
    statusFeSettings: STATUS.RESOLVED,
    errorFeSettings: null,
    ...payload
});

const handlers = {
    [ActionTypes.FE_SETTINGS_REQUEST]: handleFeSettingsRequest,
    [ActionTypes.FE_SETTINGS_SUCCESS]: handleFeSettingsSuccess,
    [ActionTypes.FE_SETTINGS_FAILURE]: handleFeSettingsFailure
};
export default createReducer(initialState, handlers);
