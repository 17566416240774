import {authUserFailureAction} from "../../redux/actions/index";
import {selectCurrentUserEmail} from "../../redux/app_selectors";
import STATUS from "../../redux/utils/status";
import logger from "../../utils/logger_pino";
import ActionTypes from "./fe_settings_action_types";
import {fetchFeSettings} from "./fe_settings_api";

const loadFeSettingsRequestAction = () => ({
    type: ActionTypes.FE_SETTINGS_REQUEST
});

const loadFeSettingsSuccessAction = (payload) => ({
    type: ActionTypes.FE_SETTINGS_SUCCESS,
    payload
});

const loadFeSettingsFailureAction = (error) => ({
    type: ActionTypes.FE_SETTINGS_FAILURE,
    error
});

/**
 * load style settings
 *
 * @param {string} organizationId
 * @return {function}
 */
function loadFeSettingsAction(organizationId) {
    return function (dispatch, getState) {
        // fetch feSettings only once
        const state = getState();
        if (state.feSettings.statusFeSettings !== STATUS.RESOLVED) {
            dispatch(loadFeSettingsRequestAction());
            fetchFeSettings(organizationId)
                .then(({data}) => {
                    const settings = {...data.data.settings, emergencyThreshold: data.data.general?.emergencyThreshold};
                    if (!settings.emergencyThreshold) {
                        const email = selectCurrentUserEmail(state);
                        logger.error("emergencyThreshold not found", {organizationId, email});
                    }
                    dispatch(loadFeSettingsSuccessAction(settings));
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        dispatch(authUserFailureAction({error: true, message: "fetch feSettings error"}));
                    } else {
                        dispatch(loadFeSettingsFailureAction(error));
                    }
                });
        }
    };
}

export {loadFeSettingsAction};
