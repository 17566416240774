import {makeStyles} from "tss-react/mui";

/**
 * @type {object}
 */
// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles()((theme) => {
    return {
        root: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            minHeight: theme.custom.headerHeight
        },
        teaserRoot: {
            top: "30px"
        },
        logo: {
            margin: "0 1rem 0 1.375rem"
        },
        toolbar: {
            padding: `0 ${theme.spacing("m")}`,
            height: theme.custom.headerHeight,
            width: "100%"
        },
        offset: {
            minHeight: theme.custom.headerHeight
        },
        menuItemWrapper: {
            display: "flex",
            alignItems: "center",
            height: "100%",
            [theme.breakpoints.down("lg")]: {
                display: "none"
            }
        },
        menuItem: {
            position: "relative",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            height: theme.custom.headerHeight,
            margin: 0,
            backgroundColor: "transparent"
        },
        menuItemGutters: {
            padding: `0 10px`
        },
        menuItemSelected: {
            "backgroundColor": "transparent !important",

            "&:after": {
                position: "absolute",
                bottom: 0,
                left: theme.spacing("s"),
                display: "inline",
                content: "''",
                height: theme.spacing("xs"),
                width: `calc(100% - ${theme.spacing("m")})`,
                background: theme.palette.primary.main
            }
        },
        link: {
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: "0.875rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            height: "100%",
            width: "100%",
            color: theme.palette.grey[700],
            textDecoration: "none",
            textTransform: "uppercase"
        },
        menuSmall: {
            marginLeft: 0,
            [theme.breakpoints.up("md")]: {
                marginLeft: theme.spacing("m")
            },
            [theme.breakpoints.up("lg")]: {
                display: "none"
            }
        },
        menu: {
            "& .MuiMenu-list": {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: `0 ${theme.spacing("xs")}`
            },
            "& .MuiPopover-paper": {
                [theme.breakpoints.up("lg")]: {
                    display: "none"
                }
            }
        },
        menuHeader: {
            fontSize: "0.875rem",
            color: theme.palette.grey[700],
            fontWeight: theme.typography.fontWeightRegular
        },
        menuHeaderText: {
            display: "none",

            [theme.breakpoints.up("sm")]: {
                display: "inline"
            }
        },
        headerItems: {
            display: "flex",
            alignItems: "center",
            position: "absolute",
            right: "1rem",
            height: "100%",
            marginLeft: theme.spacing("m")
        },
        lastUpdated: {
            paddingRight: theme.spacing("m"),
            fontSize: "0.6875rem",
            color: theme.palette.grey[500]
        },
        divider: {
            width: "1px",
            height: theme.spacing("l"),
            background: theme.palette.grey[200],
            marginRight: theme.spacing("s"),
            marginLeft: theme.spacing("s")
        },
        teaser: {
            height: "30px",
            background: "#fff5f3",
            borderBottom: "1px solid #f3d6d6",
            display: "flex",
            alignItems: "center",
            padding: "0 10px",
            color: theme.palette.error.main,
            zIndex: theme.zIndex.appBar,
            position: "relative"
        },
        teaserText: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
        },
        eventCreatedAt: {
            margin: "0 1rem",
            flexShrink: 0
        },
        badge: {
            fontSize: 1,
            height: "12px",
            minWidth: "10px",
            width: "10px"
        },
        dotPosition: {
            top: "-15%",
            right: "-4%"
        }
    };
});
