// @ts-check
import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import config from "../../../../config/config.json";
import {selectInfo1, selectInfo2} from "../../../pages/day_view/day_view_selectors";
import {selectStandardNamesArray} from "../../private_data/private_data_selectors";
import useStyles from "../info_layer.styles";

/**
 * render Info1And2 (Info1 and Info2) component
 * @return {React.ReactElement|null}
 */
export const Info1And2 = () => {
    const {classes} = useStyles();
    const {t} = useTranslation();

    const info1 = useSelector(selectInfo1);
    const info2 = useSelector(selectInfo2);

    const practitionerIds1 = info1?.[0]?.practitionerIds || [];
    const practitionerIds2 = info2?.[0]?.practitionerIds || [];

    const practitionerNamesList = useSelector(
        selectStandardNamesArray({ids: practitionerIds1.concat(practitionerIds2), type: "practitioner"})
    );

    const {
        TEXT_PUNCTUATION: {HYPHEN, VERTICAL_SLASH}
    } = config;

    const pracNames1 = practitionerIds1.map((practitionerId) => practitionerNamesList.find((name) => name.id === practitionerId)?.name);
    const pracNames2 = practitionerIds2.map((practitionerId) => practitionerNamesList.find((name) => name.id === practitionerId)?.name);
    if (!pracNames1.length && !pracNames2.length) return null;
    return (
        <div className={classes.additionalInfoWrapper}>
            <div className={classes.infoBox}>
                <div className={classes.infoBoxTitle}>{t("Info1And2.title1")}</div>
                <div className={classes.infoBoxNames}>{pracNames1.join(VERTICAL_SLASH) || HYPHEN}</div>
            </div>
            <div className={classes.infoBox}>
                <div className={classes.infoBoxTitle}>{t("Info1And2.title2")}</div>
                <div className={classes.infoBoxNames}>{pracNames2.join(VERTICAL_SLASH) || HYPHEN}</div>
            </div>
        </div>
    );
};

export default Info1And2;
