// @ts-check
import {useTheme} from "@mui/material";
import {PickersDay, pickersDayClasses} from "@mui/x-date-pickers";
import {bool, object} from "prop-types";
import React from "react";

import useStyles from "../enable_print_layer.styles";

/** @typedef {import("@mui/x-date-pickers").PickersDayProps} PickersDayProps */

/**
 * CustomPickerDay component
 * @param {Object} props
 * @param {DateTimeType} props.date
 * @param {PickersDayProps} props.pickersDayProps
 * @param {Boolean} props.selected
 * @return {React.ReactElement}
 */
const CustomPickerDayPrint = ({date, pickersDayProps, selected}) => {
    const {classes, cx} = useStyles();
    const {primary} = useTheme().palette;

    if (!date) {
        return <PickersDay {...pickersDayProps} />;
    }
    return (
        <div
            className={cx(classes.dayWrapper, {
                [classes.dayWrapperSelected]: selected && !pickersDayProps.today,
                [classes.dayWrapperToday]: pickersDayProps.today
            })}
        >
            {
                <PickersDay
                    {...pickersDayProps}
                    sx={{
                        [`&&.${pickersDayClasses.selected}`]: {
                            backgroundColor: primary.main
                        }
                    }}
                />
            }
            {pickersDayProps.today && !pickersDayProps.outsideCurrentMonth && <span className={classes.todayDot} />}
        </div>
    );
};

CustomPickerDayPrint.propTypes = {
    date: object.isRequired,
    pickersDayProps: object.isRequired, // PickersDayProps
    selected: bool
};

export default CustomPickerDayPrint;
