// @ts-check
/**
 * @fileoverview redux selectors for feSettings
 */

import {createSelector} from "reselect";

/**
 * Select the feSettings
 *
 * @param {*} state
 * @return {FrontendSettings}
 */
const selectFeSettings = (state) => state.feSettings;

/**
 * Selector for the color of each discipline
 * @param {*} state
 * @return {{name: string, hex: string}[]} The array with each color per discipline
 */
const selectDisciplineColors = (state) => selectFeSettings(state).disciplineColors;

const selectDisciplineColorsMap = createSelector(selectDisciplineColors, (state) =>
    state.reduce((accumulator, current) => {
        accumulator[current.name] = current.hex;
        return accumulator;
    }, {})
);

const selectTheme = (state) => selectFeSettings(state).theme;
const selectThemeStartTimeOfTheDay = (state) => selectTheme(state).startTimeOfTheDay;
const selectThemeLocationInfos = (state) => selectTheme(state).locations;

const selectAlias = (state) => selectFeSettings(state).alias;

const selectLogo = (state) => selectFeSettings(state).logo;

const selectStatusFeSettings = (state) => selectFeSettings(state).statusFeSettings;
const selectErrorFeSettings = (state) => selectFeSettings(state).errorFeSettings;

const selectShowVerticalLine = (state) => selectFeSettings(state).showVerticalLine;
const selectVerticalLineBackground = (state) => selectFeSettings(state).verticalLineBackground;

const selectPathTranslation = (state) => selectFeSettings(state).translation;

const selectPrint = (state) => selectFeSettings(state).print;

/**
 *
 * @param {object} state
 * @return {SurgeryAssignmentSettings}
 */
const selectSurgeryAssignmentConfig = (state) => selectFeSettings(state).surgeryAssignment;
const selectInfoLayerConfig = (state) => selectFeSettings(state).infoLayer;

// @todo to be fixed jsdoc in #15141
/**
 * selector for the participant category
 * The below condition is for the compatibility between the different versions of fe and settings but will be adjusted in the #15289
 * @param {Object} params
 * @param {string} params.hcServiceId
 * @return {ParticipantCategoriesSettings}
 */

const selectParticipantCategoriesForHealthcareService = createSelector(
    [selectFeSettings, (state, param) => param],
    ({participantCategoriesForHealthcareService}, {hcServiceId}) => {
        const hcServiceCategory = participantCategoriesForHealthcareService?.find(
            (categoryForHealthcareService) => categoryForHealthcareService.healthcareServiceId === hcServiceId
        );
        const defaultCategory = participantCategoriesForHealthcareService?.find(
            (categoryForHealthcareService) => categoryForHealthcareService.healthcareServiceId === "default"
        );
        return hcServiceCategory?.categories || defaultCategory?.categories;
    }
);

export {
    selectFeSettings,
    selectStatusFeSettings,
    selectErrorFeSettings,
    selectDisciplineColors,
    selectDisciplineColorsMap,
    selectThemeStartTimeOfTheDay,
    selectThemeLocationInfos,
    selectAlias,
    selectLogo,
    selectShowVerticalLine,
    selectVerticalLineBackground,
    selectSurgeryAssignmentConfig,
    selectPathTranslation,
    selectTheme,
    selectInfoLayerConfig,
    selectPrint,
    selectParticipantCategoriesForHealthcareService
};
