// @ts-check
import {func, string} from "prop-types";
import React from "react";

import config from "../../../../config/config.json";
import CommonTextField from "../../shared/common_text_field/common_text_field";
import useStyles from "../enable_print_layer.styles";

/**
 * DayNote component
 * @param {Object} props
 * @param {string} props.dateLabel
 * @param {string} props.note
 * @param {ChangeEventHandler} props.onChange
 * @return {React.ReactElement}
 */
const DayNote = ({dateLabel, note, onChange}) => {
    const {classes} = useStyles();
    const {MAX_LENGTH_COMMENT} = config;
    return (
        <div className={classes.textRow}>
            <span className={classes.date}>{dateLabel}</span>
            <CommonTextField
                inputProps={{maxLength: MAX_LENGTH_COMMENT}}
                minRows={1}
                multiline
                value={note}
                variant="standard"
                onChange={onChange}
            />
        </div>
    );
};
DayNote.propTypes = {
    note: string,
    dateLabel: string,
    onChange: func.isRequired
};
export default DayNote;
