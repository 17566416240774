import {makeStyles} from "tss-react/mui";

/** @type {Object} */
export default makeStyles()((theme) => ({
    root: {
        width: "100%",
        background: theme.palette.background.paper
    },
    title: {
        height: "78px",
        textTransform: "uppercase",
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        color: theme.palette.common.black,
        fontWeight: theme.typography.fontWeightBold,
        padding: "35px"
    },
    content: {
        "height": "calc(100% - 174px)", // 78 + 96
        "display": "flex",
        "flexDirection": "column",
        "justifyContent": "flex-start",
        "padding": "35px",
        "overflowY": "auto",
        "overflowX": "hidden",
        "& .MuiPickerStaticWrapper-content": {
            // workaround since className prop in the StaticDatePicker doesn't work
            background: theme.palette.background.paper
        }
    },
    dayWrapper: {
        "position": "relative",
        "& button": {
            fontSize: "15px",
            background: theme.palette.background.paper
        }
    },
    dayWrapperSelected: {
        "& button": {
            background: `${theme.palette.primary.main} !important`,
            color: `${theme.palette.primary.contrastText} !important`
        }
    },
    dayWrapperToday: {
        "& button": {
            color: `${theme.palette.primary.main} !important`
        }
    },
    todayDot: {
        width: "4px",
        height: "4px",
        borderRadius: "2px",
        backgroundColor: theme.palette.primary.main,
        position: "absolute",
        bottom: "5px",
        left: "18px"
    },
    border: {
        height: "1px",
        background: theme.palette.grey[200],
        width: "calc(100% + 60px)",
        left: "-30px",
        position: "relative"
    },
    footer: {
        height: "96px",
        borderTop: `1px solid ${theme.palette.grey[200]}`,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "0 40px"
    },
    textWrapper: {
        padding: "35px 0"
    },
    textLabel: {
        paddingBottom: "21px"
    },
    textRow: {
        display: "flex"
    },
    date: {
        "lineHeight": "40px",
        "fontWeight": theme.typography.fontWeightMedium,
        "paddingRight": "12px",
        "whiteSpace": "nowrap",
        "width": "80px",
        "textAlign": "right",
        "&:after": {
            display: "inline",
            content: '":"'
        }
    }
}));
