/**
 * @fileoverview react error boundary
 */

import HealingIcon from "@mui/icons-material/Healing";
import PropTypes from "prop-types";
import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import StackTrace from "stacktrace-js";
import {withStyles} from "tss-react/mui";

import mainMenu from "../../../config/main_menu_structure";
import {selectCurrentOrganizationId, selectCurrentUserEmail} from "../../redux/app_selectors";
import logger from "../../utils/logger_pino";
import {SitemapLinks} from "../sitemap";
import styles from "./error_boundary.styles";

/**
 * ErrorBoundary
 *
 * catch error and render an error message
 */
class ErrorBoundary extends React.Component {
    /**
     * constructor
     * @param {Object} props
     */
    constructor(props) {
        super(props);

        this.state = {
            hasError: false
        };
    }

    /**
     * react method
     * @param {Object} error
     * @return {{hasError: boolean}}
     */
    static getDerivedStateFromError(error) {
        return {
            hasError: Boolean(error)
        };
    }

    /**
     * lifecycle method
     * @param {Object} error
     * @param {Object} errorInfo
     */
    componentDidCatch(error, errorInfo) {
        const {organizationId, email} = this.props;

        const message = error?.message || error?.toString();

        StackTrace.fromError(error).then((err) => {
            const stackTrace = err?.map((e) => e.toString());
            logger.error(message, {stackTrace: stackTrace?.toString()}, {organizationId, email});
        });
    }

    /**
     * render
     * @return {jsx}
     */
    render() {
        const classes = withStyles.getClasses(this.props);
        const {children, t} = this.props;
        const {hasError} = this.state;

        if (!hasError) {
            return children;
        }

        return (
            <div className={classes.root} data-testid="error-boundary">
                <h1 className={classes.headline}>
                    <HealingIcon color="primary" fontSize="large" />
                    <span>{t("ErrorPage.headline")}</span>
                </h1>
                <p className={classes.intro} data-testid="error-message">
                    {t("ErrorPage.intro")}
                </p>

                <h2 className={classes.subheader}>{t("ErrorPage.pages")}</h2>

                <SitemapLinks menu={mainMenu} />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    organizationId: selectCurrentOrganizationId(state),
    email: selectCurrentUserEmail(state)
});

ErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.shape({
        root: PropTypes.string,
        headline: PropTypes.string,
        intro: PropTypes.string,
        subheader: PropTypes.string
    }),
    t: PropTypes.func.isRequired,
    organizationId: PropTypes.string,
    email: PropTypes.string
};

export default withTranslation()(connect(mapStateToProps)(withStyles(ErrorBoundary, styles)));
