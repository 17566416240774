// @ts-check
import {Tooltip} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import config from "../../../../config/config.json";
import {selectInfo3} from "../../../pages/day_view/day_view_selectors";
import {selectStandardNamesArray} from "../../private_data/private_data_selectors";
import getPersonIdsFromInfo from "../../private_data/utils/get_person_ids_from_info";
import {formatInfo3} from "../helpers";
import useStyles from "../info_layer.styles";

/**
 * Render Info3 component
 * For the DHM, it is used to show on-call duties
 *
 * @return {React.ReactElement}
 */
export const Info3 = () => {
    const {classes} = useStyles();
    const {t} = useTranslation();

    const info3 = useSelector(selectInfo3);

    const practitionerNamesList = useSelector(selectStandardNamesArray({ids: getPersonIdsFromInfo(info3), type: "practitioner"}));

    const {
        TEXT_PUNCTUATION: {HYPHEN, VERTICAL_SLASH}
    } = config;

    const mergedInfo3List = info3 ? formatInfo3(info3) : [];

    return (
        <div className={classes.Info3Wrapper}>
            <div className={classes.info3}>
                <div className={classes.infoBoxTitle}>{t("Info3.title")}</div>
                {mergedInfo3List.map((duty) => {
                    const pracNames = duty.practitionerIds.map(
                        (practitionerId) => practitionerNamesList.find((name) => name.id === practitionerId)?.name
                    );
                    return (
                        <div className={classes.dutieRow} key={duty.role}>
                            <div className={classes.label}>{t(`Info3.${duty.role}`)}</div>
                            <Tooltip title={pracNames.join(VERTICAL_SLASH) || HYPHEN}>
                                <div className={classes.value}>{pracNames.join(VERTICAL_SLASH) || HYPHEN}</div>
                            </Tooltip>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Info3;
