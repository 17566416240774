// @ts-check
import {t} from "i18next";
import uniq from "lodash/uniq";

import config from "../../../config/config.json";
import {luxonToken} from "../../contexts/dates";
import {isEmergencyFunc} from "../../utils/is_emergency";
import {areSameDates, format, getDateTimeFromISOWithTimezone} from "../../utils/luxon_helpers";
/**
 * format multiple names (format names from the given ids)
 *
 * @param {Array<string>} ids
 * @param {Array<{id: string, name: string}>} namesList
 * @param {string} connector
 * @return {string}
 */
const formatNames = (ids, namesList, connector) => {
    return ids.map((id) => namesList.find((name) => name.id === id)?.name).join(` ${connector} `);
};

/**
 * Map the condition for the change logs
 *
 * example:
 * practitionerChange should include "Practitioner" in the path
 * cancelled should have status === "cancelled"
 */
const conditions = {
    practitionerChange: {path: "Practitioner"},
    locationChange: {path: "Location"},
    startChange: {path: "start"},
    newEmergency: {path: "authoredOn", isEmergency: true},
    newSurgery: {path: "authoredOn", isEmergency: false},
    cancelled: {path: "status", value: "cancelled"},
    revoked: {path: "status", value: "revoked"},
    planned: {path: "status", value: "booked"}
};

/**
 * @typedef {object} ChangesByCategories
 * @property {Array<ResourceHistoryChange>} practitionerChange
 * @property {Array<ResourceHistoryChange>} locationChange
 * @property {Array<ResourceHistoryChange>} startChange
 * @property {Array<ResourceHistoryChange>} newEmergency
 * @property {Array<ResourceHistoryChange>} newSurgery
 * @property {Array<ResourceHistoryChange>} cancelled
 * @property {Array<ResourceHistoryChange>} revoked
 * @property {Array<ResourceHistoryChange>} planned
 */

/**
 * Format the change content within a row
 * all the practitonerChanges should be shown in the two elements (add, removed)
 * all the other change will be formatted accordingly (from/to, or just set the translation)
 *
 * @param {ChangesByCategories} changesByCategories
 * @param {Array<{id: string, name: string}>} namesList
 * @param {Array<{id: string, name: string}>} roomInfos
 * @param {string} timezone
 * @param {string} language
 * @return {Array<{key: string, content: string}>}
 */
const formatChangeContent = (changesByCategories, namesList, roomInfos, timezone, language) => {
    const {
        TEXT_PUNCTUATION: {HYPHEN, VERTICAL_SLASH}
    } = config;

    const resultKeyContent = [];
    // Added practitioners
    const addedPractitionerNames = changesByCategories.practitionerChange?.length
        ? formatNames(
              changesByCategories.practitionerChange.filter((change) => change.value).map((change) => change.value),
              namesList,
              VERTICAL_SLASH
          )
        : "";
    if (addedPractitionerNames)
        resultKeyContent.push({key: addedPractitionerNames, content: `${t("ChangeLogs.plus")} ${addedPractitionerNames}`});

    // Removed practitioners
    const removedPractitionerNames = changesByCategories.practitionerChange?.length
        ? formatNames(
              changesByCategories.practitionerChange.filter((change) => change.valueBefore).map((change) => change.valueBefore),
              namesList,
              VERTICAL_SLASH
          )
        : "";
    if (removedPractitionerNames)
        resultKeyContent.push({key: removedPractitionerNames, content: `${t("ChangeLogs.minus")} ${removedPractitionerNames}`});

    // location change
    changesByCategories.locationChange?.forEach((change) => {
        const before = roomInfos.find((room) => room.id === change.valueBefore)?.name || HYPHEN;
        const after = roomInfos.find((room) => room.id === change.value)?.name || HYPHEN;
        resultKeyContent.push({key: before + after, content: `${t("ChangeLogs.room")} ${before} ${t("ChangeLogs.changedTo")} ${after}`});
    });

    // start time change
    changesByCategories.startChange?.forEach((change) => {
        const valueDT = getDateTimeFromISOWithTimezone(change.value, timezone);
        const valueBeforeDT = getDateTimeFromISOWithTimezone(change.valueBefore, timezone);
        const isDateChanged = !areSameDates(valueBeforeDT, valueDT, "day", timezone);
        const tokenFormat = isDateChanged ? luxonToken.DATE_TIME_SHORT_FORMAT[language] : luxonToken.TIME_FORMAT[language];
        // Since the resource history handles all the ISO timestamps in UTC, set the timezone in FE
        const before = change.valueBefore ? format(valueBeforeDT, tokenFormat) : HYPHEN;
        const after = change.value ? format(valueDT, tokenFormat) : HYPHEN;
        resultKeyContent.push({key: before + after, content: `${t("ChangeLogs.start")} ${before} ${t("ChangeLogs.changedTo")} ${after}`});
    });
    // new emergency
    changesByCategories.newEmergency?.forEach((_) => resultKeyContent.push({key: "newEmergency", content: t("ChangeLogs.newEmergency")}));

    // new surgery
    changesByCategories.newSurgery?.forEach((_) => resultKeyContent.push({key: "newSurgery", content: t("ChangeLogs.newOP")}));

    // on-hold (cancelled)
    changesByCategories.cancelled?.forEach((_) => resultKeyContent.push({key: "cancelled", content: t("ChangeLogs.onHold")}));

    // permanently removed (revoked)
    changesByCategories.revoked?.forEach((_) => resultKeyContent.push({key: "cancelled", content: t("ChangeLogs.revoked")}));

    // planned
    changesByCategories.planned?.forEach((_) => resultKeyContent.push({key: "planned", content: t("ChangeLogs.planned")}));

    return resultKeyContent;
};

/**
 * Format the changes by the categories
 * For the categories of newEmergency and newEmergency, changes have different minimum strucutre since they come from the op
 *
 * @param {Array<ResourceHistoryChange>} changes these changes will be shown for one row (the changes made to the same op at same time)
 * @param {Array<{id: string, name: string}>} namesList
 * @param {Array<{id: string, name: string}>} roomInfos
 * @param {string} timezone
 * @param {string} language
 * @param {boolean} isEmergency
 * @return {Array<{key: string, content: string}>}
 */
const formatChangesByCategories = (changes, namesList, roomInfos, timezone, language, isEmergency) => {
    /** @type ChangesByCategories */
    const changesByCategories = {
        practitionerChange: [],
        locationChange: [],
        startChange: [],
        newEmergency: [],
        newSurgery: [],
        cancelled: [],
        revoked: [],
        planned: []
    };
    Object.entries(conditions).forEach(([type, condition]) => {
        changesByCategories[type] = changes.filter((change) => {
            return Object.entries(condition).every(([key, value]) => {
                if (key === "isEmergency") {
                    return isEmergency === value;
                }
                if (typeof change[key] === "string") {
                    return change[key].includes(value);
                }
                return change[key] === value;
            });
        });
    });

    return formatChangeContent(changesByCategories, namesList, roomInfos, timezone, language);
};

/**
 * format new surgeries to be able to show in the change log table
 *
 * @param {Array<PlanBox>} opData
 * @param {number} emergencyThreshold
 * @return {Array<ResourceHistoryChangeNewSurgery>}
 */
const formatNewSurgeriesFromOpData = (opData, emergencyThreshold) => {
    return opData.map((op) => ({
        id: op.id,
        patientId: op._patient?.id,
        start: op._internalTimestamps?.duraRoomLockPre?.dtStart,
        isEmergency: isEmergencyFunc(op._priority, emergencyThreshold), // special for the new emergency
        change: {
            timestamp: op._authoredOn,
            path: "authoredOn"
        }
    }));
};
/**
 * Sort change logs by timestamp and id descending
 *
 * @param {ResourceHistoryChangeInfoLayer|ResourceHistoryChangeNewSurgery} a
 * @param {ResourceHistoryChangeInfoLayer|ResourceHistoryChangeNewSurgery} b
 * @return {number}
 */
const sortChangeLogs = (a, b) => (b.change.timestamp + b.id).localeCompare(a.change.timestamp + a.id);

/**
 * format all the changes to the changes for each rows. i.e, gather the changes made to the same surgery at the same time
 *
 * @param {Array<ResourceHistoryChangeInfoLayer>} allChanges
 * @return {Array<ResourceHistoryChangeInfoLayerTable>}
 */
const formatChangesPerRow = (allChanges) => {
    let changePerRow = null;
    const changesPerRowList = [];
    allChanges.sort(sortChangeLogs).forEach((changeHistory) => {
        // check if an id and timestamp are different (= new row)
        if (changePerRow?.id !== changeHistory.id || changePerRow?.timestamp !== changeHistory.change.timestamp) {
            if (changePerRow) {
                changesPerRowList.push(changePerRow);
            }
            const {id, patientId, start, change, isEmergency} = changeHistory;
            changePerRow = {id, patientId, start, timestamp: change.timestamp, isEmergency, changes: [change]};
        } else {
            changePerRow.changes.push(changeHistory.change);
        }
    });
    if (changePerRow) {
        changesPerRowList.push(changePerRow);
    }
    return changesPerRowList;
};

export const orderOfDuties = {
    onCallSpecial1: 1,
    onCall1: 2,
    standBy1: 3,
    anesOnCallDay1: 4,
    anesOnCallNight1: 5,
    anesOnCallEarly1: 6,
    anesStandBy1: 7,
    pediatricOnCall1: 8
};

const formatInfo3 = (info3) => {
    const mergedInfo3Duties = [];

    for (const duty of info3) {
        const existingDuty = mergedInfo3Duties.find((mergedDuty) => mergedDuty.role === duty.role);
        if (!existingDuty) {
            mergedInfo3Duties.push({...duty});
        } else {
            existingDuty.practitionerIds.push(...duty.practitionerIds);
        }
    }
    return mergedInfo3Duties
        .map((duty) => ({...duty, practitionerIds: uniq(duty.practitionerIds)}))
        .sort((a, b) => orderOfDuties[a.role] - orderOfDuties[b.role]);
};

export {formatNames, formatChangesByCategories, formatNewSurgeriesFromOpData, sortChangeLogs, formatChangesPerRow, formatInfo3};
