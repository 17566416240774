const styles = (theme) => ({
    root: {
        padding: "3rem",
        color: "#333333",
        backgroundColor: "#eeeeee",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100vw"
    },
    headline: {
        "display": "flex",
        "alignItems": "center",
        "margin": `0 0 1rem 0`,
        "fontSize": "2.125rem",
        "fontWeight": "normal",
        "color": theme.palette.primary.main,
        "textTransform": "uppercase",

        "& span": {
            marginLeft: "0.5rem"
        }
    },
    intro: {
        margin: `0.5rem 0 2rem`,
        fontSize: "1rem",
        fontWeight: 300
    },
    subheader: {
        "margin": `3rem 0 1rem 0`,
        "fontSize": "1rem",
        "fontWeight": 500,

        "&:after": {
            display: "inline",
            content: '":"'
        }
    }
});

export default styles;
