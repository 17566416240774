// @ts-check
import MenuIcon from "@mui/icons-material/Menu";
import {AppBar, Badge, Box, Button, Menu, MenuItem, Toolbar} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import {array, arrayOf, func, shape, string} from "prop-types";
import React, {Fragment, useContext, useEffect, useMemo, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {Link} from "react-router-dom";

import {TEASER_TRANSLAION_KEY} from "../../../config/event_config";
import {DATE_FORMATS, DateContext} from "../../contexts/dates";
import {
    selectLastPublishedSession,
    selectManualChanges,
    selectSavedManualChanges,
    selectSessionEditedBy,
    selectSessionPublished,
    selectUsernames
} from "../../pages/op_management/op_management_selectors";
import {selectSurgeryAssignmentWarningCount} from "../../pages/surgery_assignment/surgery_assignment_selectors";
import {selectCurrentUserEmail, selectCurrentUserRoles, selectLanguage} from "../../redux/app_selectors";
import {setShowTeaser} from "../../redux/events/event_actions";
import {selectShowTeaser} from "../../redux/events/event_selectors";
import formatUsername from "../../utils/format_username";
import {filterAllowedItems} from "../../utils/menu";
import {useSecurity} from "../../utils/security";
import AccountMenu from "./account_menu";
import {useStyles} from "./header.style.js";
import Logo from "./logo";

/**
 * The header component
 *
 * @param {object} props
 * @param {MenuStructure} props.menuStructure The menu structure
 * @param {string} props.defaultRoute The default route
 * @param {Function} props.renderOptions A function to render extra options to the menu. For example renderHeaderOptions() inside the DayView component
 * @param {string} props.editedBy The name of the last person that edited the plan
 * @param {Array<React.ReactElement>} [props.headerItems=[]] The extra header items for the header. For example, the button to go to full screen mode.
 * @return {React.ReactNode}
 */
const Header = ({menuStructure, defaultRoute, renderOptions, editedBy, headerItems = []}) => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();
    const downSm = useMediaQuery((/** @type {Object}} */ theme) => theme.breakpoints.down("md"));
    const {formatFromISO} = useContext(DateContext);

    // State
    const [anchorEl, setAnchorEl] = useState(null);
    const [teaserTranslationKey, setTeaserTranslationKey] = useState(""); // TEASER_TRANSLAION_KEY
    const [teaserUpdatedAt, setTeaserUpdatedAt] = useState();

    const {permissions} = useSecurity();

    // redux
    // from websocket event
    const showTeaser = useSelector(selectShowTeaser);
    const hasWarnings = Boolean(useSelector(selectSurgeryAssignmentWarningCount));

    // Info from customer
    const language = useSelector(selectLanguage);
    const userEmail = useSelector(selectCurrentUserEmail);
    const userRoles = useSelector(selectCurrentUserRoles);

    // Info from /state call
    const {publishedAt, publishedBy} = useSelector(selectLastPublishedSession);
    const usernames = useSelector(selectUsernames);
    const openManualChanges = useSelector(selectManualChanges);
    const completeManualChanges = useSelector(selectSavedManualChanges);
    const sessionEditedBy = useSelector(selectSessionEditedBy);
    const sessionPublished = useSelector(selectSessionPublished);

    useEffect(() => {
        if (!sessionPublished && (openManualChanges?.length || completeManualChanges?.length)) {
            dispatch(setShowTeaser(true));
            // set eventKey
            // The one, who can edit the plan, will see the special message for the op manager (key: manualChangesToBePublishedOpManager)
            // Case A: someone is editing the plan -> the person who is editing the plan will see this message
            // Case B: all the manual changes were already saved -> all op-managers (who have a role of op-manager) will see this message
            const isOpManager = sessionEditedBy ? sessionEditedBy === userEmail : userRoles.includes("op-manager");
            const key = isOpManager
                ? TEASER_TRANSLAION_KEY.manualChangesToBePublishedOpManager
                : TEASER_TRANSLAION_KEY.manualChangesToBePublished;
            setTeaserTranslationKey(key);
            // set latest timest
            const allChangesSorted = openManualChanges.concat(completeManualChanges).sort((a, b) => (a.timest > b.timest ? -1 : 1));
            setTeaserUpdatedAt(allChangesSorted[0].timest);
        } else {
            dispatch(setShowTeaser(false));
            setTeaserTranslationKey(null);
            setTeaserUpdatedAt(null);
        }
    }, [openManualChanges, completeManualChanges]);

    const menu = useMemo(() => filterAllowedItems(menuStructure, permissions), [menuStructure, permissions]);

    const currentRoute = location.pathname.length > 1 ? location.pathname : defaultRoute;
    const currentCategory = menu.find((menuItem) => currentRoute.startsWith(menuItem.route));
    const currentPage =
        currentCategory && currentCategory.pages ? currentCategory.pages.find((page) => currentRoute.startsWith(page.route)) : undefined;

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const menu_items_jsx =
        currentCategory && currentCategory.pages
            ? currentCategory.pages.map((page) => (
                  <MenuItem
                      classes={{
                          gutters: classes.menuItemGutters,
                          selected: classes.menuItemSelected
                      }}
                      className={classes.menuItem}
                      key={`${currentCategory.name}-${page.name}`}
                      selected={currentPage.name === page.name}
                      title={t(page.translationKey)}
                  >
                      <Badge
                          badgeContent=" "
                          classes={{badge: classes.badge, anchorOriginTopRightCircular: classes.dotPosition}}
                          color="error"
                          invisible={!page.hasBadge || !hasWarnings}
                          overlap="circular"
                      >
                          <Link className={classes.link} to={page.route} onClick={handleCloseMenu}>
                              {t(page.translationKey)}
                          </Link>
                      </Badge>
                  </MenuItem>
              ))
            : [];

    const menu_items_jsx_popup = [...menu_items_jsx];

    const lastUpdated = publishedAt && publishedBy && (
        <div key={"last-publication"} style={{display: "flex"}}>
            <div className={classes.lastUpdated}>
                <div>{t("Header.lastPublication1")}</div>
                <Trans
                    components={{bold: <strong />}}
                    i18nKey="Header.lastPublication2"
                    values={{
                        date: formatFromISO(publishedAt, DATE_FORMATS.DATE),
                        time: formatFromISO(publishedAt, DATE_FORMATS.TIME),
                        name: formatUsername(publishedBy, usernames)
                    }}
                />
            </div>
            {Boolean((editedBy && editedBy !== userEmail) || headerItems.length) && <div className={classes.divider} />}
        </div>
    );

    const allHeaderItems = [...headerItems];
    if (currentRoute !== "/account") {
        allHeaderItems.unshift(lastUpdated);
    }

    allHeaderItems.push(<AccountMenu editedBy={editedBy} key="account-menu" />);

    const hoverText = downSm ? t(`Header.teaser.${teaserTranslationKey}`) : "";
    return (
        <Fragment>
            {showTeaser && (
                <div className={classes.teaser} title={hoverText}>
                    {<img alt={"new"} src={language === "de" ? "/images/icons/neu.svg" : "/images/icons/new.svg"} />}
                    <span className={classes.eventCreatedAt}>
                        <strong>
                            {t("Header.teaser.eventCreatedAt", {
                                date: formatFromISO(teaserUpdatedAt, DATE_FORMATS.DATE),
                                time: formatFromISO(teaserUpdatedAt, DATE_FORMATS.TIME)
                            })}
                        </strong>
                    </span>
                    <span className={classes.teaserText}>{t(`Header.teaser.${teaserTranslationKey}`)}</span>
                </div>
            )}
            <AppBar
                className={cx(classes.root, {
                    [classes.teaserRoot]: showTeaser
                })}
                color="inherit"
                elevation={2}
                position="absolute"
            >
                <Logo className={classes.logo} />

                <Toolbar className={classes.toolbar}>
                    {menu_items_jsx.length ? <Box className={classes.menuItemWrapper}>{menu_items_jsx}</Box> : null}

                    {renderOptions && renderOptions()}

                    {menu_items_jsx.length ? (
                        <Box className={classes.menuSmall}>
                            <Button className={classes.menuHeader} startIcon={<MenuIcon color="primary" />} onClick={handleClickMenu}>
                                <span className={classes.menuHeaderText}>{t("Header.menu")}</span>
                            </Button>
                            <Menu
                                anchorEl={anchorEl}
                                anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                                className={classes.menu}
                                elevation={3}
                                keepMounted
                                open={Boolean(anchorEl)}
                                PaperProps={{square: true}}
                                transformOrigin={{vertical: "top", horizontal: "right"}}
                                onClose={handleCloseMenu}
                            >
                                {menu_items_jsx_popup}
                            </Menu>
                        </Box>
                    ) : null}
                    <div className={classes.headerItems}>{allHeaderItems}</div>
                </Toolbar>
            </AppBar>

            <div className={classes.offset} />
        </Fragment>
    );
};

Header.propTypes = {
    defaultRoute: string.isRequired,
    menuStructure: arrayOf(
        shape({
            icon: string,
            name: string.isRequired,
            translationKey: string.isRequired,
            route: string.isRequired,
            pages: arrayOf(
                shape({
                    name: string.isRequired,
                    route: string.isRequired,
                    translationKey: string.isRequired
                })
            )
        })
    ).isRequired,
    headerItems: array,
    renderOptions: func,
    editedBy: string
};

export default Header;
